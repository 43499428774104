export const initialCalculator = {
  product: 'auto',
  zipCode: '',
  monthlyIncome: '',
  collateralValue: '',
  autoManufacturingYear: '',
  loanTerm: '12',
  intendedCredit: '',
}

export const CURRENCY_PLACEHOLDERS = {
  auto: {
    monthlyIncome: 'R$ 1.200,00',
    collateralValue: 'R$ 15.000,00',
    intendedCredit: 'R$ 5.000,00',
  },
  home: {
    monthlyIncome: 'R$ 3.000,00',
    collateralValue: 'R$ 150.000,00',
    intendedCredit: 'R$ 30.000,00',
  },
}

export const COLLATERAL_INTEGER_LIMITS = {
  auto: 7,
  home: 8,
}

export const CALCULATOR_MODES = [
  {
    value: 'auto',
    text: 'Automóvel',
  },
  {
    value: 'home',
    text: 'Imóvel',
  },
]

export const ACCEPTABLE_LOAN_TERMS = {
  auto: [
    { value: 24, text: '24 meses' },
    { value: 36, text: '36 meses' },
    { value: 48, text: '48 meses' },
    { value: 60, text: '60 meses' },
  ],
  home: [
    { value: 60, text: '60 meses' },
    { value: 90, text: '90 meses' },
    { value: 120, text: '120 meses' },
    { value: 150, text: '150 meses' },
    { value: 180, text: '180 meses' },
    { value: 210, text: '210 meses' },
    { value: 240, text: '240 meses' },
  ],
}
