import {
  ACCEPTABLE_LOAN_TERMS,
  CALCULATOR_MODES,
  COLLATERAL_INTEGER_LIMITS,
  CURRENCY_PLACEHOLDERS,
} from 'constants/Calculator'
import React from 'react'
import { BlockDivider, BlockDividerTitle } from '@creditas/block-divider'
import { AmountField } from 'components/Calculator/AmountField'
import { any, func } from 'prop-types'
import { RadioInputField } from '@creditas/input'
import { YearField } from 'components/Calculator/YearField'
import { useTranslation } from 'react-i18next'

const propTypes = {
  values: any.isRequired,
  handleChange: func.isRequired,
  onBlur: func.isRequired,
  touched: any.isRequired,
  errors: any.isRequired,
  setFieldValue: any.isRequired,
}

const CollateralInformation = ({
  values,
  handleChange,
  onBlur,
  touched,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation(['calculator'])

  const onRadioChange = event => {
    setFieldValue('product', event)
    setFieldValue('loanTerm', ACCEPTABLE_LOAN_TERMS[event][0].value)
  }

  return (
    <BlockDivider>
      <BlockDividerTitle>
        {t('form.collateralInformation.title')}
      </BlockDividerTitle>
      <RadioInputField
        value="auto"
        options={CALCULATOR_MODES}
        onChange={onRadioChange}
        noOutline
      />
      <AmountField
        label={t('form.collateralInformation.fields.collateralAmount')}
        integerLimit={COLLATERAL_INTEGER_LIMITS[values.product]}
        placeholder={CURRENCY_PLACEHOLDERS[values.product].collateralValue}
        id="collateralValue"
        name="collateralValue"
        value={values.collateralValue}
        onChange={handleChange}
        onBlur={onBlur}
        error={touched.collateralValue && errors.collateralValue}
        success={touched.collateralValue && !errors.collateralValue}
        feedbackText={touched.collateralValue && errors.collateralValue}
      />
      {values.product === 'auto' && (
        <YearField
          label={t('form.collateralInformation.fields.autoManufacturingYear')}
          placeholder="2016"
          id="autoManufacturingYear"
          name="autoManufacturingYear"
          value={values.autoManufacturingYear}
          onChange={handleChange}
          onBlur={onBlur}
          error={touched.autoManufacturingYear && errors.autoManufacturingYear}
          success={
            touched.autoManufacturingYear && !errors.autoManufacturingYear
          }
          feedbackText={
            touched.autoManufacturingYear && errors.autoManufacturingYear
          }
        />
      )}
    </BlockDivider>
  )
}

CollateralInformation.propTypes = propTypes

export default CollateralInformation
