import * as yup from 'yup'
import { unmaskCurrency, unmaskYear } from 'utils/masks'

const productSchema = yup.string().required('Este campo é obrigatório.')

const zipCodeSchema = yup
  .string()
  .required('Este campo é obrigatório.')
  .matches(/^\d{5}-\d{3}$/i, 'CEP inválido.')

const monthlyIncomeAuto = yup
  .number()
  .required('Este campo é obrigatório.')
  .min(1200, 'O valor mínimo é de R$ 1.200,00')

const monthlyIncomeHome = yup
  .number()
  .required('Este campo é obrigatório.')
  .min(3000, 'O valor mínimo é de R$ 3.000,00')

const monthlyIncomeSchema = yup
  .number()
  .transform((value, originalValue) => {
    const unmaskedValue = unmaskCurrency(originalValue)
    return parseFloat(unmaskedValue)
  })
  .when('product', {
    is: 'auto',
    then: monthlyIncomeAuto,
    otherwise: monthlyIncomeHome,
  })

const intendedCreditAuto = yup
  .number()
  .required('Este campo é obrigatório.')
  .min(5000, 'O valor mínimo é de R$ 5.000,00')

const intendedCreditHome = yup
  .number()
  .required('Este campo é obrigatório.')
  .min(30000, 'O valor mínimo é de R$ 30.000,00')

const intendedCreditSchema = yup
  .number()
  .transform((value, originalValue) => {
    const unmaskedValue = unmaskCurrency(originalValue)
    return parseFloat(unmaskedValue)
  })
  .when('product', {
    is: 'auto',
    then: intendedCreditAuto,
    otherwise: intendedCreditHome,
  })

const collateralAuto = yup.number().required('Este campo é obrigatório')

const collateralHome = yup
  .number()
  .required('Este campo é obrigatório.')
  .min(150000, 'O valor mínimo da garantia é de R$ 150.000,00')

const collateralSchema = yup
  .number()
  .transform((value, originalValue) => {
    const unmaskedValue = unmaskCurrency(originalValue)
    return parseFloat(unmaskedValue)
  })
  .when('product', {
    is: 'auto',
    then: collateralAuto,
    otherwise: collateralHome,
  })

const manufacturingYearAuto = yup
  .number()
  .required('Este campo é obrigatório.')
  .integer('Este campo deve ser um número inteiro.')
  .min(new Date().getFullYear() - 15, 'Não aceitamos veículos deste ano.')
  .max(new Date().getFullYear(), 'Ano inválido.')

const manufacturingYearHome = yup.number()

const manufacturingYearSchema = yup
  .number()
  .transform((value, originalValue) => unmaskYear(originalValue))
  .when('product', {
    is: 'auto',
    then: manufacturingYearAuto,
    otherwise: manufacturingYearHome,
  })

export const calculatorSchema = yup.object().shape({
  product: productSchema,
  zipCode: zipCodeSchema,
  monthlyIncome: monthlyIncomeSchema,
  collateralValue: collateralSchema,
  autoManufacturingYear: manufacturingYearSchema,
  intendedCredit: intendedCreditSchema,
})

export const fieldValidation = async ({
  setFieldTouched,
  setFieldError,
  schema,
  field,
  value,
}) => {
  setFieldTouched(field, true, false)

  try {
    await schema.fields[field].validate(value)
    setFieldError(field, null)

    return null
  } catch ({ message }) {
    setFieldError(field, message)

    return message
  }
}
