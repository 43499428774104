import {
  ACCEPTABLE_LOAN_TERMS,
  CURRENCY_PLACEHOLDERS,
} from 'constants/Calculator'
import React from 'react'
import { BlockDivider, BlockDividerTitle } from '@creditas/block-divider'
import { AmountField } from 'components/Calculator/AmountField'
import { any, func } from 'prop-types'
import { SelectField } from 'components/Calculator/SelectField'
import { useTranslation } from 'react-i18next'

const propTypes = {
  values: any.isRequired,
  handleChange: func.isRequired,
  onBlur: func.isRequired,
  touched: any.isRequired,
  errors: any.isRequired,
}

const LoanInformation = ({ values, handleChange, onBlur, touched, errors }) => {
  const { t } = useTranslation(['calculator'])

  return (
    <BlockDivider>
      <BlockDividerTitle>{t('form.loanInformation.title')}</BlockDividerTitle>
      <SelectField
        label={t('form.loanInformation.fields.loanTerm')}
        id="loanTerm"
        name="loanTerm"
        value={values.loanTerm}
        onChange={handleChange}
        onBlur={onBlur}
        options={ACCEPTABLE_LOAN_TERMS[values.product]}
      />
      <AmountField
        label={t('form.loanInformation.fields.intendedCredit')}
        placeholder={CURRENCY_PLACEHOLDERS[values.product].intendedCredit}
        id="intendedCredit"
        name="intendedCredit"
        value={values.intendedCredit}
        onChange={handleChange}
        onBlur={onBlur}
        error={touched.intendedCredit && errors.intendedCredit}
        success={touched.intendedCredit && !errors.intendedCredit}
        feedbackText={touched.intendedCredit && errors.intendedCredit}
      />
    </BlockDivider>
  )
}

LoanInformation.propTypes = propTypes

export default LoanInformation
