import { CURRENCY_PLACEHOLDERS } from 'constants/Calculator'
import React, { useState } from 'react'
import { BlockDivider, BlockDividerTitle } from '@creditas/block-divider'
import { ZipCodeField } from 'components/Calculator/ZipCodeField'
import { AmountField } from 'components/Calculator/AmountField'
import { any, func } from 'prop-types'
import { useTranslation } from 'react-i18next'

const propTypes = {
  values: any.isRequired,
  handleChange: func.isRequired,
  onBlur: func.isRequired,
  touched: any.isRequired,
  errors: any.isRequired,
}

const PersonalInformation = ({
  values,
  handleChange,
  onBlur,
  touched,
  errors,
}) => {
  const [zipCodeError, setZipCodeError] = useState('')
  const { t } = useTranslation(['calculator'])

  return (
    <BlockDivider>
      <BlockDividerTitle>
        {t('form.personalInformation.title')}
      </BlockDividerTitle>
      <ZipCodeField
        placeholder="00000-000"
        label={t('form.personalInformation.fields.zipCode')}
        id="zipCode"
        name="zipCode"
        value={values.zipCode}
        onBlur={onBlur}
        onChange={handleChange}
        onFetchAddress={(redirect, error) => {
          setZipCodeError(error)
        }}
        error={touched.zipCode && (errors.zipCode || zipCodeError)}
        success={touched.zipCode && !errors.zipCode && !zipCodeError}
        feedbackText={
          (touched.zipCode && errors.zipCode) ||
          (touched.zipCode && zipCodeError)
        }
      />
      <AmountField
        placeholder={CURRENCY_PLACEHOLDERS[values.product].monthlyIncome}
        label={t('form.personalInformation.fields.monthlyIncome')}
        id="monthlyIncome"
        name="monthlyIncome"
        value={values.monthlyIncome}
        onChange={handleChange}
        onBlur={onBlur}
        error={touched.monthlyIncome && errors.monthlyIncome}
        success={touched.monthlyIncome && !errors.monthlyIncome}
        feedbackText={touched.monthlyIncome && errors.monthlyIncome}
      />
    </BlockDivider>
  )
}

PersonalInformation.propTypes = propTypes

export default PersonalInformation
