import React, { useState } from 'react'
import { string, shape, func } from 'prop-types'
import { Typography } from '@creditas/typography'
import { Button } from '@creditas/button'
import {
  ResultColumn,
  ResultContainer,
  ResultItem,
  ResultRow,
} from './calculatorResult.style'

const propTypes = {
  product: string.isRequired,
  simulation: shape({
    loanAmount: string.isRequired,
    installmentAmount: string.isRequired,
    loanTerm: string.isRequired,
    monthlyInterestRate: string.isRequired,
    totalEffectiveCost: string.isRequired,
  }).isRequired,
  getDocument: func.isRequired,
}

const capitalize = word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`

const CalculatorResult = ({ product, simulation, getDocument }) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const downloadSimulation = async () => {
    setIsDownloading(true)
    const encodedDocumentData = await getDocument()

    setIsDownloading(false)
    const dataUrl = window.URL.createObjectURL(
      new Blob([encodedDocumentData], { type: 'application/pdf' }),
    )
    const anchor = document.createElement('a')
    anchor.href = dataUrl
    anchor.setAttribute('download', `simulacao_de_emprestimo_${product}.pdf`)
    anchor.click()
  }

  return (
    simulation !== null && (
      <ResultContainer>
        <ResultItem>
          <Typography variant="h5">
            Simulação de empréstimo {capitalize(product)}
          </Typography>
        </ResultItem>

        <ResultItem>
          <Typography variant="support">Valor do empréstimo</Typography>
          <Typography variant="h6">{simulation.loanAmount}</Typography>
        </ResultItem>

        <ResultColumn>
          <ResultRow>
            <ResultItem>
              <Typography variant="support">Valor da parcela</Typography>
              <Typography variant="h6">
                {simulation.installmentAmount}
              </Typography>
            </ResultItem>

            <ResultItem>
              <Typography variant="support">Número de parcelas</Typography>
              <Typography variant="h6">{simulation.loanTerm}</Typography>
            </ResultItem>
          </ResultRow>

          <ResultRow>
            <ResultItem>
              <Typography variant="support">Taxa mensal*</Typography>
              <Typography variant="h6">
                {simulation.monthlyInterestRate}
              </Typography>
            </ResultItem>

            <ResultItem>
              <Typography variant="support">Taxa CET*</Typography>
              <Typography variant="h6">
                {simulation.totalEffectiveCost}
              </Typography>
            </ResultItem>
          </ResultRow>
        </ResultColumn>

        <ResultItem>
          <Typography variant="support">
            *As taxas representam apenas uma estimativa inicial. Cadastre os
            dados completos do cliente para obter os valores exatos.
          </Typography>
        </ResultItem>

        <Button
          onClick={() => downloadSimulation()}
          fullWidth={true}
          isLoading={isDownloading}
          disabled={isDownloading}
        >
          Baixar documento da simulação
        </Button>
      </ResultContainer>
    )
  )
}

CalculatorResult.propTypes = propTypes

export { CalculatorResult }
