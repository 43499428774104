import { useState, useEffect } from 'react'
import { calculatorService } from 'services/calculator'
import { decamelizeKeys } from 'humps'

function useAddress() {
  const [zipCode, setZipCode] = useState('')
  const [error, setError] = useState('')
  const [redirect, setRedirect] = useState(false)

  function handleError(statusCode) {
    switch (statusCode) {
      case 200:
        setError('')
        break

      case 401:
        setRedirect(true)
        break

      default:
        setError('CEP não encontrado.')
        break
    }
  }

  useEffect(() => {
    const isZipCodeValid = zipCode.match(/^\d{5}-\d{3}/i) !== null
    if (!isZipCodeValid) return

    const isValidAddress = async () => {
      try {
        const response = await calculatorService().axiosInstance.request({
          url: '/mediator/address',
          params: decamelizeKeys({
            zipCode,
          }),
          validateStatus: false,
        })

        return response.status
      } catch (err) {
        return 500
      }
    }

    isValidAddress(zipCode).then(handleError)
  }, [zipCode])

  return [redirect, error, setZipCode]
}

export { useAddress }
