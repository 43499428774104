import { calculatorService } from 'services/calculator'
import { unmaskCurrency, unmaskZipCode } from 'utils/masks'
import { decamelizeKeys, camelizeKeys } from 'humps'
import VMasker from 'vanilla-masker'

export const useCalculator = () => {
  const formatRate = value => `${(value * 100).toFixed(2).replace('.', ',')}%`
  const formatMoney = value => {
    const currencyValue = parseFloat(value).toFixed(2)

    return VMasker.toMoney(currencyValue, {
      separator: ',',
      delimiter: '.',
      unit: 'R$',
    })
  }

  const formatResponse = simulation => {
    simulation.loanAmount = formatMoney(simulation.loanAmount)
    simulation.installmentAmount = formatMoney(simulation.installmentAmount)
    simulation.monthlyInterestRate = formatRate(simulation.monthlyInterestRate)
    simulation.totalEffectiveCost = formatRate(simulation.totalEffectiveCost)

    return simulation
  }

  const getSimulation = async ({
    product,
    zipCode,
    collateralValue,
    autoManufacturingYear,
    loanTerm,
    intendedCredit,
    monthlyIncome,
  }) => {
    try {
      const response = await calculatorService().axiosInstance.request({
        url: `/affiliates/${product}/refinancing/simulations`,
        params: decamelizeKeys({
          affiliateNameAlias: 'aee',
          affiliateFullName: 'aee',
          affiliateType: 'aeee',
          zipCode: unmaskZipCode(zipCode),
          collateralValue: unmaskCurrency(collateralValue),
          autoManufacturingYear,
          loanTerm,
          intendedCredit: unmaskCurrency(intendedCredit),
          monthlyIncome: unmaskCurrency(monthlyIncome),
        }),
      })

      if (response.status !== 200) {
        return undefined
      }

      return formatResponse(camelizeKeys(response.data.simulation))
    } catch (error) {
      return undefined
    }
  }

  const getDocument = async ({
    product,
    zipCode,
    collateralValue,
    autoManufacturingYear,
    loanTerm,
    intendedCredit,
    monthlyIncome,
  }) => {
    try {
      const response = await calculatorService().axiosInstance.request({
        url: `/affiliates/${product}/refinancing/document`,
        responseType: 'arraybuffer',
        params: decamelizeKeys({
          zipCode: unmaskZipCode(zipCode),
          collateralValue: unmaskCurrency(collateralValue),
          autoManufacturingYear,
          loanTerm,
          intendedCredit: unmaskCurrency(intendedCredit),
          monthlyIncome: unmaskCurrency(monthlyIncome),
        }),
      })

      if (response.status !== 201) {
        return undefined
      }

      return response.data
    } catch (error) {
      return undefined
    }
  }

  return { getSimulation, getDocument }
}
