import React from 'react'
import { any } from 'prop-types'
import MaskedInput from 'react-text-mask'
import { TextField } from '@creditas/input'

const propTypes = {
  mask: any.isRequired,
}

const MaskedField = props => (
  <MaskedInput
    {...props}
    render={(ref, innerProps) => <TextField inputRef={ref} {...innerProps} />}
  />
)

MaskedField.propTypes = propTypes

export { MaskedField }
