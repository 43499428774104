import { utilities, styled } from '@creditas/stylitas'

export const ResultContainer = styled.div`
  ${utilities.form.focus}
  ${utilities.radius.medium};
  padding: 20px 40px;
  margin: 20px;
  height: max-content;
  flex-grow: 1;
  text-align: center;
`

export const ResultItem = styled.div`
  margin: 15px 0px;
`

export const ResultColumn = styled.div`
  display: flex;
  justify-content: space-around;
`

export const ResultRow = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`
