import React from 'react'
import { func, oneOf, string, number, shape, arrayOf } from 'prop-types'
import { TextField } from '@creditas/input'

const propTypes = {
  label: string,
  id: string,
  name: string,
  value: oneOf([number, string]),
  onChange: func,
  onBlur: func,
  options: arrayOf(
    shape({
      value: oneOf([number, string]).isRequired,
      text: oneOf([number, string]).isRequired,
    }),
  ).isRequired,
}

const SelectField = ({ label, id, name, value, onChange, onBlur, options }) => {
  const selectOptions = options.map((option, index) => (
    <option key={index} value={option.value}>
      {option.text}
    </option>
  ))

  return (
    <TextField
      label={label}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      select
    >
      {selectOptions}
    </TextField>
  )
}

SelectField.propTypes = propTypes

export { SelectField }
