import axios from 'axios'

const calculatorService = () => {
  const axiosInstance = axios.create({
    method: 'GET',
    baseURL: process.env.CALCULATOR_GATEWAY,
  })

  axiosInstance.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        'creditas-affiliates-token',
      )}`

      return config
    },
    error => {
      return Promise.reject(error)
    },
  )

  return { axiosInstance }
}

export { calculatorService }
