import React from 'react'
import { any, number } from 'prop-types'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { MaskedField } from '../MaskedField'

const propTypes = {
  valueLimit: number,
  integerLimit: number,
  props: any,
}

const defaultProps = {
  valueLimit: null,
  integerLimit: null,
}

const defaultCurrencyMask = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  requireDecimal: true,
  allowDecimal: true,
  allowNegative: false,
  allowLeadingZeroes: false,
}

const currencyMask = createNumberMask({
  ...defaultCurrencyMask,
  integerLimit: 8,
})

const AmountField = ({ valueLimit, integerLimit, ...props }) => {
  const mask =
    valueLimit === null
      ? currencyMask
      : createNumberMask({
          ...defaultCurrencyMask,
          valueLimit,
          integerLimit,
        })

  return <MaskedField mask={mask} data-testid="amount-field" {...props} />
}

AmountField.propTypes = propTypes
AmountField.defaultProps = defaultProps

export { AmountField }
