import React from 'react'
import { any } from 'prop-types'
import { MaskedField } from '../MaskedField/MaskedField'

const propTypes = {
  props: any,
}

const YearField = ({ ...props }) => (
  <MaskedField
    mask={[/\d/, /\d/, /\d/, /\d/]}
    data-testid="year-field"
    {...props}
  />
)

YearField.propTypes = propTypes

export { YearField }
