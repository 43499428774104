import { initialCalculator } from 'constants/Calculator'
import React, { useState } from 'react'
import { Button } from '@creditas/button'
import { useToaster } from '@creditas/toaster'
import { CalculatorResult } from 'components/Calculator/Result'
import {
  calculatorSchema,
  fieldValidation,
} from 'components/Calculator/Form/schemas'
import { useFormik } from 'formik'
import { useCalculator } from 'components/Calculator/useCalculator'
import { Form } from 'components/Calculator/Form/calculatorForm.style'
import PersonalInformation from 'components/Calculator/Form/PersonalInformation'
import CollateralInformation from 'components/Calculator/Form/CollateralInformation'
import LoanInformation from 'components/Calculator/Form/LoanInformation'
import withAuthentication from 'utils/withAuthentication'

const CalculatorPage = () => {
  const [simulation, setSimulation] = useState(null)
  const { getSimulation, getDocument } = useCalculator()
  const { push } = useToaster()

  const submitSimulation = async (values, { setSubmitting }) => {
    const result = await getSimulation(values)
    if (result === undefined) {
      push('Ocorreu um erro! Por favor tente novamente mais tarde', {
        level: 'danger',
      })
    } else {
      setSimulation(result)
    }
    setSubmitting(false)
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    setFieldError,
    setFieldValue,
  } = useFormik({
    initialValues: initialCalculator,
    validationSchema: calculatorSchema,
    onSubmit: submitSimulation,
  })

  const onBlur = async event => {
    event.persist()

    const fieldName = event.target.name
    const fieldValue = event.target.value

    await fieldValidation({
      setFieldTouched,
      setFieldError,
      schema: calculatorSchema,
      field: fieldName,
      value: fieldValue,
    })

    return handleBlur(event)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <PersonalInformation
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          onBlur={onBlur}
        />
        <CollateralInformation
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          onBlur={onBlur}
        />
        <LoanInformation
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          onBlur={onBlur}
        />
        <Button
          fullWidth={true}
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Simular
        </Button>
      </Form>

      <CalculatorResult
        product={values.product}
        simulation={simulation}
        getDocument={() => getDocument(values)}
      />
    </>
  )
}

export default withAuthentication(CalculatorPage)
