import React, { useEffect, useRef } from 'react'
import { any, func } from 'prop-types'
import { MaskedField } from '../MaskedField'
import { useAddress } from './useAddress'

const propTypes = {
  onChange: func.isRequired,
  onFetchAddress: func.isRequired,
  props: any,
}

const ZipCodeField = ({ onFetchAddress, onChange, ...props }) => {
  const [redirect, error, setZipCode] = useAddress()

  const handleError = useRef(onFetchAddress)

  function handleChange(event) {
    setZipCode(event.target.value)
    return onChange && onChange(event)
  }

  useEffect(() => {
    handleError.current(redirect, error)
  }, [redirect, error])

  return (
    <MaskedField
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      data-testid="zip-code-field"
      onChange={handleChange}
      {...props}
    />
  )
}

ZipCodeField.propTypes = propTypes

export { ZipCodeField }
