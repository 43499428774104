export const unmaskCurrency = value => {
  return value.match(/\d/g) === null
    ? 0
    : value
        .replace(/[a-zA-z!@#$%^&* ]/g, '')
        .replace(/\./g, '')
        .replace(',', '.')
        .match(/\d*\.\d*/g)[0]
}

export const unmaskZipCode = value => {
  return value.match(/\d/g) === null ? value : value.replace(/-/g, '')
}

export const unmaskYear = value => {
  const numbers = value.match(/\d*/g)
  return numbers === null ? 0 : numbers.join('')
}
